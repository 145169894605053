import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../services/apiService';

function AdminPanel() {
  const [error, setError] = useState('');
  const { adminUsers, setAdminUsers, getAuthHeader } = useAuth(); // Use adminUsers directly from context
  const authHeader = getAuthHeader();

  const handleRoleChange = async (userId, newRole) => {
    try {
      // Update the user's role via the API and in the context state
      await apiService.updateUserRole(authHeader, userId, newRole);
      
      // Update the role in the local state
      setAdminUsers((users) => users.map((user) => (user._id === userId ? { ...user, role: newRole } : user)));
    } catch (err) {
      setError('Error updating user role');
    }
  };

  const tableCellStyles = {
    p: 1,
    textAlign: 'center',
    fontSize: '12px',
  };

  const columnHeaders = ['email', 'company']; // Headers for table columns

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h4" gutterBottom>
        Admin
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnHeaders.map((header) => (
                <TableCell key={header} sx={{ textAlign: 'center', fontSize: '14px' }}>
                  {header.charAt(0).toUpperCase() + header.slice(1)}
                </TableCell>
              ))}
              <TableCell sx={{ textAlign: 'center', fontSize: '14px' }}>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminUsers.map((user) => (
              <TableRow key={user._id} sx={{ height: 'auto', p: 0 }}>
                {columnHeaders.map((key) => (
                  <TableCell key={key} sx={tableCellStyles}>
                    {user[key]}
                  </TableCell>
                ))}
                <TableCell sx={tableCellStyles}>
                  <FormControl fullWidth sx={{ m: 0 }}>
                    <Select
                      value={user.role}
                      onChange={(e) => handleRoleChange(user._id, e.target.value)}
                      sx={{
                        p: 0, // Remove padding
                        '& .MuiSelect-select': {
                          padding: '6px', // Adjust padding inside the select
                        },
                      }}
                    >
                      <MenuItem value="user" sx={{ p: 0 }}>User</MenuItem>
                      <MenuItem value="admin" sx={{ p: 0 }}>Admin</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AdminPanel;