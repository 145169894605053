// src/components/SignUpFee.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { styled } from '@mui/system'; 
import {
  Box,
  Button,
  Paper,
  CircularProgress,
  Alert,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../services/apiService';

import { formatCurrency } from '../utils/Utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardElementContainer = styled('div')({
  padding: '15px 10px',
  borderRadius: '4px',
  border: '1px solid #ced4da',
  marginTop: '16px',
  backgroundColor: '#f8f9fa',
});


const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#495057',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      '::placeholder': {
        color: '#868e96',
      },
    },
    invalid: {
      color: '#fa755a',
    },
  },
  hidePostalCode: true,
  autoComplete: 'off',
};


const SignUpFeeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [response, setResponse] = useState(null);

  const navigate = useNavigate();
  const { user, setUser, getAuthHeader } = useAuth(); // Destructure setStatus from context
  const authHeader = getAuthHeader();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
  
    setLoading(true);
    setError(null);
    setResponse(null);
  
    try {  

        // Using a new card
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          setError('Card details not found.');
          setLoading(false);
          return;
        }
  
        // Create and confirm Payment Intent
        const paymentIntent = await apiService.createPaymentIntentAndConfirm(
          69,
          user,
          stripe,
          cardElement,
          authHeader,
          saveCard
        );
  
      const result = paymentIntent;
      
  
      console.log('Payment Result:', result); // Log the entire result
  
      if (result.error) {
        setError(result.error.message);
      } else if (result.paymentIntent?.status === 'succeeded') {
        const newCredits = await apiService.updateCredits(user, 69, authHeader);
        setResponse(`\nBalance: ${formatCurrency(newCredits)}`);
        setUser((prevUser) => ({ ...prevUser, credits: newCredits }));
        setTimeout(navigate('/leads'), 1000);
      } else if (result.paymentIntent?.status === 'requires_action') {
        // Handle additional authentication if required
        setError('Additional authentication required. Please check your payment method.');
      } else {
        setError('Payment failed. Please try again.');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };




  return (
    <Box sx={{ maxWidth: 500, mx: 'auto', p: 2 }}>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Complete Your Sign-Up ($69)
        </Typography>
    
        <form onSubmit={handleSubmit} >
          <CardElementContainer>
            <CardElement options={cardElementOptions} />
          </CardElementContainer>

          <FormControlLabel
            control={
              <Checkbox
                checked={saveCard}
                onChange={(e) => setSaveCard(e.target.checked)}
              />
            }
            label="Save card for future use"
          />

          <Box sx={{ position: 'relative', mt: 2 }}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Pay'}
          </Button>
          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <Typography variant="h6" color="error">
                Error
              </Typography>
              <pre>{error}</pre>
            </Alert>
          )}
          {response && (
            <Alert severity="success" sx={{ mt: 2 }}>
              <Typography variant="h6">Payment Successful!</Typography>
              Redirecting...
            </Alert>
          )}
        </form>
      </Paper>
    </Box>
  );
};

const SignUpFee = () => (
  <Elements stripe={stripePromise}>
    <SignUpFeeForm />
  </Elements>
);

export default SignUpFee;








 



   


  