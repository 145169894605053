// src/components/AddCredits.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { styled } from '@mui/system';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Modal,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AuthWrapper from './AuthWrapper';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../services/apiService';
// import { formatCurrency } from '../utils/Utils';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const TOKEN_TO_DOLLAR_RATIO = process.env.TOKEN_TO_DOLLAR_RATIO || 5; // Default to 5 if not set

// Styled components for consistent styling
const CardElementContainer = styled('div')({
  padding: '15px 10px',
  borderRadius: '4px',
  border: '1px solid #ced4da',
  marginTop: '16px',
  backgroundColor: '#f8f9fa',
});

const StyledTextField = styled(TextField)({
  marginBottom: '16px',
  '& label': {
    color: '#495057',
  },
  '& .MuiInputBase-root': {
    color: '#495057',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ced4da',
    },
    '&:hover fieldset': {
      borderColor: '#868e96',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#495057',
    },
  },
});

const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#495057',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      '::placeholder': {
        color: '#868e96',
      },
    },
    invalid: {
      color: '#fa755a',
    },
  },
  hidePostalCode: true,
};

const PaymentForm = ({ user, setUser, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { getAuthHeader } = useAuth();
  const authHeader = getAuthHeader();

  const [loading, setLoading] = useState(false);
  const [fetchingCards, setFetchingCards] = useState(true);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [storedCards, setStoredCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState('new'); // Default to 'new' card
  const [saveCard, setSaveCard] = useState(false);
  const [amount, setAmount] = useState('');

  // Fetch stored cards on component mount
  useEffect(() => {
    const fetchStoredCards = async () => {
      try {
       
        const data = await apiService.fetchStoredCards(user.id, authHeader);
        console.log('Fetched Stored Cards:', data); // Debugging
        setStoredCards(data || []);
      } catch (err) {
        console.error('Error fetching stored cards:', err);
      } finally {
        setFetchingCards(false);
      }
    };
    fetchStoredCards();
  }, [user.id, authHeader]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Convert entered tokens to dollar amount for backend processing
    const amountValue = parseFloat(amount) * TOKEN_TO_DOLLAR_RATIO; // amount in dollars
    // console.log("amount in dollars", amountValue)
    if (isNaN(amountValue) || amountValue <= 0) {
      setError('Please enter a valid token amount.');
      return;
    }
    
    
    if (!stripe || !elements) return;
  
    setLoading(true);
    setError(null);
    setResponse(null);
  
    try {
      let clientSecret;
      let result;
  
      if (selectedCard !== 'new') {
        // Using a saved card
        const paymentIntentResponse = await apiService.createPaymentIntent(
          amountValue,
          user,
          authHeader,
          false
        );
        clientSecret = paymentIntentResponse.clientSecret;
  
        result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: selectedCard,
        });
      } else {
        // Using a new card
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          setError('Card details not found.');
          setLoading(false);
          return;
        }
  
        // Create and confirm Payment Intent
        const paymentIntent = await apiService.createPaymentIntentAndConfirm(
          amountValue,
          user,
          stripe,
          cardElement,
          authHeader,
          saveCard
        );
  
        result = paymentIntent;
      }
  
      console.log('Payment Result:', result); // Log the entire result for debugging
  
      if (result.error) {
        setError(result.error.message);
      } else if (result.paymentIntent?.status === 'succeeded') {
        const newCredits = await apiService.updateCredits(user, amountValue, authHeader); // Use tokens as input
        setResponse(`\nBalance: ${Math.floor(newCredits / TOKEN_TO_DOLLAR_RATIO)} Tokens`); // Display balance in tokens
        setUser((prevUser) => ({ ...prevUser, credits: newCredits }));
        setTimeout(handleClose, 1000);
      } else if (result.paymentIntent?.status === 'requires_action') {
        // Handle additional authentication if required
        setError('Additional authentication required. Please check your payment method.');
      } else {
        setError('Payment failed. Please try again.');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCard = async (cardId) => {
    try {
      const response = await apiService.removeStoredCard(user.id, cardId, authHeader);
      setResponse(response.message)
      setStoredCards((prevCards) => prevCards.filter((card) => card.id !== cardId));
    } catch (err) {
      console.error('Error removing card:', err);
      setError('Failed to remove card.');
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ p: 2, mb: 2, position: 'relative' }}>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 1 }}>
  <Typography variant="h4" gutterBottom>
    Add Tokens
  </Typography>
  <Typography variant="h6" gutterBottom>
    1 Token = ${TOKEN_TO_DOLLAR_RATIO}
  </Typography>
</Box>

        <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 1 }}>

          <StyledTextField
            label="Token Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            margin="normal"
            required
            inputProps={{ min: '1', step: '1' }}
          />

          {fetchingCards ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <FormControl fullWidth sx={{ mt: 0 }}>
            <InputLabel id="saved-cards-label">Select Card</InputLabel>
            <Select
              labelId="saved-cards-label"
              value={selectedCard}
              onChange={(e) => setSelectedCard(e.target.value)}
              label="Select Card"
              renderValue={(selected) => {
                const selectedCard = storedCards.find((card) => card.id === selected);
                return selectedCard ? `**** **** **** ${selectedCard.card.last4} - ${selectedCard.card.exp_month}/${selectedCard.card.exp_year}` : 'Use New Card';
              }}
            >
              <MenuItem value="new">Use New Card</MenuItem>
              {storedCards
                .filter((card) => card.card) // Ensure 'card' exists
                .map((card) => (
                  <MenuItem key={card.id} value={card.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {`**** **** **** ${card.card.last4} - ${card.card.exp_month}/${card.card.exp_year}`}
                    <IconButton onClick={() => handleRemoveCard(card.id)} color="error">
                      <RemoveCircleIcon />
                    </IconButton>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          )}
</Box>

          {selectedCard === 'new' && (
            <>
              <CardElementContainer>
                <CardElement options={cardElementOptions} />
              </CardElementContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveCard}
                    onChange={(e) => setSaveCard(e.target.checked)}
                  />
                }
                label="Save card for future use"
              />
            </>
          )}

          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : `Pay $${amount * TOKEN_TO_DOLLAR_RATIO} AUD`}
          </Button>

          {response && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {response}
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </form>
      </Paper>
    </Box>
  );
};

const AddCredits = ({ user, setUser, isOpen, setIsOpen }) => {
  const handleClose = () => setIsOpen(false);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: { xs: '90%', sm: '80%', md: '50%' },
          maxWidth: 600,
          p: 0,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <AuthWrapper user={user}>
          <Elements stripe={stripePromise}>
            <PaymentForm user={user} setUser={setUser} handleClose={handleClose} />
          </Elements>
        </AuthWrapper>
      </Box>
    </Modal>
  );
};

export default AddCredits;
