// src/services/apiService.js
import axios from 'axios';

const apiService = (() => {
  const fetchUserProfile = async (authHeader) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/profile`,
      {
        headers: { Authorization: authHeader },
      }
    );
    const userProfile = response.data;
    console.log(response.data);
    // Update session storage if necessary, but prefer updating state
    return userProfile;
  };

  const fetchAvailableLeads = async (
    authHeader,
    search = '',
    loanAmountMinimum = 'None',
    state = 'All',
    date = "All"
  ) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/leads`,
      {
        params: {
          fields: 'name,price,phone,email,details,purchasedCount,date',
          search,
          loanAmountMinimum,
          state,
          date,
          limit: 'none',
          obfuscate: true,
          purchased: 'user',
        },
        headers: { Authorization: authHeader },
      }
    );
    console.log(response);
    const availableLeads = response.data.leads;
    const availableLeadsCount = availableLeads.length;
    // Update session storage if necessary, but prefer updating state
    return { availableLeads, availableLeadsCount };
  };

  const fetchPurchasedLeads = async (authHeader) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/leads`,
      {
        params: { onlyPurchasedByUser: 'true', limit: 'none' },
        headers: { Authorization: authHeader },
      }
    );
  
    const purchasedLeads = response.data.leads;
    const settledCount = purchasedLeads.filter((lead) => lead.settled).length;
    const conversionRate = Number(
      Math.round(
        purchasedLeads.length ? (settledCount / purchasedLeads.length) * 100 : 0
      )
    );
  
    return { purchasedLeads, settledCount, conversionRate };
  };

  const fetchAdminUsers = async (authHeader) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/users`,
      {
        headers: { Authorization: authHeader },
      }
    );
    const adminUsers = response.data;
    // Update session storage if necessary, but prefer updating state
    return adminUsers;
  };

  const updateUserRole = async (authHeader, userId, newRole) => {
    return await axios.put(
      `${process.env.REACT_APP_API_URL}/api/admin/users/${userId}`,
      { role: newRole },
      { headers: { Authorization: authHeader } }
    );
  };


  const handlePurchase = async (authHeader, leadId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/leads/${leadId}/purchase`,
        {},
        {
          headers: { Authorization: authHeader },
        }
      );

      console.log(response);

      // Fetch the updated leads after purchase
      const purchasedLeads = await fetchPurchasedLeads(authHeader);
      console.log('Purchased leads updated:', purchasedLeads);

      const { availableLeads, availableLeadsCount } = await fetchAvailableLeads(
        authHeader
      );
      console.log('Available leads updated:', availableLeads);

      return { availableLeads, availableLeadsCount };
    } catch (err) {
      console.error('There was an error purchasing the lead!', err);
      let errorMessage = 'An unexpected error occurred. Please try again later.';
      if (err.response && err.response.data && err.response.data.code) {
        switch (err.response.data.code) {
          case 'ALREADY_PURCHASED':
            errorMessage = 'You have already purchased this lead.';
            break;
          case 'INSUFFICIENT_CREDITS':
            errorMessage = 'You do not have enough credits to purchase this lead.';
            break;
          case 'LEAD_NOT_FOUND':
            errorMessage = 'The lead you are trying to purchase was not found.';
            break;
          default:
            errorMessage = 'An unexpected error occurred. Please try again later.';
        }
      }
      throw new Error(errorMessage);
    }
  };


  const updateLeadSettledStatus = async (email, checked, userId, authHeader) => {
    try {
      // Make a PUT request to update the settled status
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/leads/settled`,
        { email, userId, settled: checked },
        { headers: { Authorization: authHeader } }
      );
  
      // Log or return the response for further processing
      return response.data; // Return data for further use (if needed)
    } catch (error) {
      console.error('Error updating lead settled status:', error);
  
      // Optionally, throw the error to handle it at the calling level
      throw new Error('There was an issue updating the settled status');
    }
  };


const createPaymentIntent = async (amount, user, authHeader, saveCard) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/payments/create-payment-intent`,
      {
        amount: parseFloat(amount) * 100, // Convert to cents
        userId: user.id,
        saveCard,
      },
      {
        headers: { Authorization: authHeader },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};


  const createPaymentIntentAndConfirm = async (
    amount,
    user,
    stripe,
    cardElement,
    authHeader,
    saveCard // New parameter
  ) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payments/create-payment-intent`,
        {
          amount: parseFloat(amount) * 100,
          userId: user.id,
          saveCard, // Send this to the backend
        },
        {
          headers: { Authorization: authHeader },
        }
      );
  
      const paymentMethod = {
        card: cardElement,
        billing_details: {
          name: `${user.firstName || ''} ${user.lastName || ''}`,
          email: user.email || '',
        },
      };
  
      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: paymentMethod,
        setup_future_usage: saveCard ? 'off_session' : undefined, // Save for future use if selected
      });
  
      return result;
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };
  
  const fetchStoredCards = async (UserId, authHeader) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/payments/stored-cards/${UserId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log(data)
      return data;
    } catch (error) {
      console.error('Error fetching stored cards:', error);
      throw error;
    }
  };

  const removeStoredCard = async (userId, cardId, authHeader) => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/payments/stored-cards/${userId}/${cardId}`, 
    
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
   // Check if response is successful
   if (response.status === 200 || response.status === 204) {
    return response.data;
  }
  throw new Error('Failed to remove card');
} 


  const updateCredits = async (user, amountValue, authHeader) => {
    const updateResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/payments/update-credits`,
      { credits: amountValue, userId: user.id },
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
    const newCredits = updateResponse.data.balance;
    return newCredits
  }
  
  const trackAction = async (actionType, authHeader, userId, options = {}) => {
    const { subType = null, details = null } = options; // Destructure with default values
  
    try {
      // Build the payload object dynamically based on the provided parameters
      const payload = {
        userId,        // Required: The ID of the user performing the action
        actionType,    // Required: e.g., 'filterChange'
      };
  
      if (subType) payload.subType = subType; // Optional: e.g., 'state'
      if (details) payload.details = details; // Optional: Extra details if provided
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tracking/track`,
        payload, // Payload with optional subType and details
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error('Error tracking action:', error.message);
      return null;
    }
  };


  return {
    fetchUserProfile,
    fetchAvailableLeads,
    fetchPurchasedLeads,
    fetchAdminUsers,
    updateUserRole,
    handlePurchase,
    updateLeadSettledStatus,
    createPaymentIntentAndConfirm,
    createPaymentIntent,
    updateCredits,
    trackAction,
    fetchStoredCards,
    removeStoredCard
  };
})();

export default apiService;