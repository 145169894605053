import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useLayout } from '../../contexts/LayoutContext';

import apiService from '../../services/apiService';
import AuthWrapper from '../AuthWrapper';
import LeadsFiltersComponent from './LeadsFiltersComponent';
import LeadsListComponent from './LeadsListComponent';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import ExpandedLead from './ExpandedLead';

function LeadsList() { // Removed user and setUser props
  const {
    user,
    setUser,
    getAuthHeader,
    availableLeads,
    setAvailableLeads,
    availableLeadsCount,
    setAvailableLeadsCount,
    setPurchasedLeads,

  } = useAuth(); // Access via context

  const {
isMobile
  } = useLayout()
  const initialLoadCount = 25;

  // Consolidated filters state
  const [filters, setFilters] = useState({
    search: '',
    state: 'All',
    loanAmountMinimum: 'None',
    date: 'All'
  });

  const [leadsToShow, setLeadsToShow] = useState([]);
  const [loading, setLoading] = useState(false); // Use loading for all fetch operations
  const [expandedLeadId, setExpandedLeadId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [response, setResponse] = useState(null);
  const [isResettingFilters, setIsResettingFilters] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isChangingFilters, setIsChangingFilters] = useState(false); // New state for changing filters

  // Fetch leads when filters change, but only if not resetting filters
  useEffect(() => {
    if (!isResettingFilters && isChangingFilters) {
      // console.log(isChangingFilters)
      // console.log(isResettingFilters)

      const fetchLeads = async () => {
        const authHeader = getAuthHeader();
        setLoading(true);

        try {
          const { availableLeads, availableLeadsCount } = await apiService.fetchAvailableLeads(
            authHeader,
            filters.search,
            filters.loanAmountMinimum,
            filters.state,
            filters.date
          );
          setAvailableLeads(availableLeads);
          setAvailableLeadsCount(availableLeadsCount);
          setLeadsToShow(availableLeads.slice(0, initialLoadCount));
        } catch (error) {
          console.error('Error fetching leads:', error);
        } finally {
          setLoading(false);
          setIsChangingFilters(false); // Reset the flag once fetching is done
          // setIsResettingFilters(true);

        }
      };

      fetchLeads();
    }
  }, [filters, isResettingFilters, isChangingFilters, getAuthHeader, setAvailableLeads, setAvailableLeadsCount]);

  // Update leadsToShow when availableLeads change
  useEffect(() => {
    setLeadsToShow(availableLeads.slice(0, initialLoadCount));
  }, [availableLeads]);

  // Load more leads
  function loadMoreLeads() {
    if (leadsToShow.length >= availableLeadsCount) {
      setLoadingMore(false);
      return;
    }

    setTimeout(() => {
      setLeadsToShow((prevLeads) => [
        ...prevLeads,
        ...availableLeads.slice(prevLeads.length, prevLeads.length + initialLoadCount),
      ]);
      setLoadingMore(false);
    }, 1000); // Simulate network delay
  }

  // Handle scroll event
  const handleScroll = (e) => {
    if (loadingMore) return;

    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Calculate how far the user is from the bottom
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

    // Adjust the threshold as needed (e.g., 100 pixels)
    if (distanceFromBottom <= 100) {
      setLoadingMore(true);
      loadMoreLeads();
    }
  };

  const handleTrackFilterChange = useCallback((filter) => {
    const authHeader = getAuthHeader(); // Fetch the latest authHeader
    const userId = user?.id; // Extract user ID from the AuthContext
  
    // Prepare the options object with subType as the filter type
    const options = {
      subType: filter, // Set the subType to the filter type
    };
  
    // Call the apiService to track the action
    apiService.trackAction('filterChange', authHeader, userId, options);
  }, [getAuthHeader, user?.id]); // Ensure dependencies are properly tracked
  
 // Handler functions
 const handleSearchChange = useCallback((e) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    search: e.target.value,
  }));
  
  handleTrackFilterChange('search'); // Call the helper function

  setIsChangingFilters(true);
}, [handleTrackFilterChange]);

const handleStateChange = useCallback((newState) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    state: newState,
  }));
  
  handleTrackFilterChange('state'); // Call the helper function

  setIsChangingFilters(true);
}, [handleTrackFilterChange]);

const handleAmountChange = useCallback((newAmount) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    loanAmountMinimum: newAmount,
  }));
  
  handleTrackFilterChange('amount'); // Call the helper function

  setIsChangingFilters(true);
}, [handleTrackFilterChange]);

const handleDateChange = useCallback((newLeadDate) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    date: newLeadDate,
  }));

  handleTrackFilterChange('date'); // Call the helper function

  setIsChangingFilters(true);
}, [handleTrackFilterChange]);



const resetFilters = async () => {
  setIsResettingFilters(true);

  setFilters({
    search: '',
    state: 'All',
    loanAmountMinimum: 'None',
    date: 'All'
  });
  
  handleTrackFilterChange('reset'); // Call the helper function

  setIsResettingFilters(false);
  setIsChangingFilters(true);
};

  const handleExpandClick = (leadId) => {
    setExpandedLeadId(leadId);
  };

  const handleBackToList = () => {
    setExpandedLeadId(null);
  };

  const handlePurchase = async (leadId) => {
    setLoading(true);
    setResponse(null);

    try {
      const authHeader = getAuthHeader();

      // Purchase the lead
      const { availableLeads, availableLeadsCount } = await apiService.handlePurchase(authHeader, leadId, setResponse);
      const { purchasedLeads } = await apiService.fetchPurchasedLeads(authHeader)
      // Show the "Purchase successful!" message
      setResponse('Purchase successful!');

      // Delay collapse and refresh for 1 second to ensure the success message is visible
      setTimeout(async () => {
        // Update leads after purchase
        setAvailableLeads(availableLeads);
        setAvailableLeadsCount(availableLeadsCount);
        setLeadsToShow(availableLeads.slice(0, initialLoadCount));
        setPurchasedLeads(purchasedLeads)
        // Update user profile
        const userProfile = await apiService.fetchUserProfile(authHeader);
        setUser(userProfile);

        // Close expanded lead
        setExpandedLeadId(null);

        // Clear the response message
        setResponse(null);
      }, 1500); // Delay for 1 second to keep the message visible

    } catch (error) {
      console.error('Error purchasing lead:', error);
      setResponse('Error purchasing lead. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const leadsListAndFilters = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Keep the height setting
      }}
    >
      <LeadsFiltersComponent
        filters={filters}
        handleSearchChange={handleSearchChange}
        handleStateChange={handleStateChange}
        handleAmountChange={handleAmountChange}
        handleDateChange={handleDateChange}
        leadsCount={availableLeadsCount}
        resetFilters={resetFilters}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        isMobile={isMobile}
      />
      <LeadsListComponent
        leadsToShow={leadsToShow}
        onScroll={handleScroll}
        loadingMore={loadingMore}
        handleExpandClick={handleExpandClick}
      />
    </Box>
  );

  const expandedLeadComponent = expandedLeadId ? (
    <ExpandedLead
      lead={availableLeads.find((lead) => lead._id === expandedLeadId)}
      isMobile={isMobile}
      handleBackToList={handleBackToList}
      loading={loading}
      handlePurchase={handlePurchase}
      response={response}
    />
  ) : null;

  return (
    <AuthWrapper user={user}>
      <Box
        sx={{
          backgroundColor: '#F5F7FA',
          minHeight: '100vh',
          paddingBottom: 'env(safe-area-inset-bottom, 50px)',
        }}
      >
        {isMobile ? (
          <MobileView
            leadsListComponent={leadsListAndFilters}
            expandedLeadComponent={expandedLeadComponent}
          />
        ) : (
          <DesktopView
            leadsListComponent={leadsListAndFilters}
            expandedLeadComponent={expandedLeadComponent}
          />
        )}
      </Box>
    </AuthWrapper>
  );
}

export default LeadsList;