// src/components/NavBar.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddCredits from './AddCredits';
// import SignUpModal from './SignUpFeeModal';
// import { formatCurrency } from '../utils/Utils';
import { useAuth } from '../contexts/AuthContext';
import { useLayout } from '../contexts/LayoutContext';
const TOKEN_TO_DOLLAR_RATIO = process.env.TOKEN_TO_DOLLAR_RATIO || 5; // Default to 5 if not set

function NavBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addCreditsOpen, setAddCreditsOpen] = useState(false);
  const { user, setUser } = useAuth()
  const { isMdUp, isMobile } = useLayout()
  const navigate = useNavigate();

const handleLogout = () => {
  sessionStorage.clear();
  setUser(null); // Add this line to reset the user state
  navigate('/login');
};

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleAddCreditsClick = () => {
    setAddCreditsOpen(true);
  };

  const renderNavItems = (isButton = false) => {
    const items = [
      { label: 'Dashboard', path: '/dashboard' },
      { label: 'Purchased', path: '/my-leads' },
      { label: 'Webhook', path: '/webhook' },
      ...(user?.role === 'admin' ? [{ label: 'Admin', path: '/admin' }] : []),
      { label: 'Logout', onClick: handleLogout },
    ];

    return items.map((item) =>
      isButton ? (
        <Button
          key={item.label}
          color="inherit"
          component={item.path ? Link : undefined}
          to={item.path}
          onClick={item.onClick}
          sx={buttonStyles}
        >
          {item.label}
        </Button>
      ) : (
        <ListItemButton
          key={item.label}
          component={item.path ? Link : undefined}
          to={item.path}
          onClick={item.onClick}
        >
          <ListItemText primary={item.label} />
        </ListItemButton>
      )
    );
  };

  const buttonStyles = {
    minWidth: 'auto',
    padding: 1,
    fontSize: { xs: '0.775rem', sm: '0.875rem' },
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar
          disableGutters
          sx={{
            minHeight: '56px',
            maxHeight: '56px',
            justifyContent: 'space-between',
            padding: '8px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {/* Show MenuIcon only on small screens */}
            {!isMdUp && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
              The Lead Matrix
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexWrap: 'nowrap',
            }}
          >
          {user?.credits !== undefined && (
  <Button
    color="inherit"
    onClick={handleAddCreditsClick}
    sx={buttonStyles}
  >
    {Math.floor(user.credits / TOKEN_TO_DOLLAR_RATIO)} Tokens
  </Button>
)}
            <Button
              color="inherit"
              component={Link}
              to="/leads"
              sx={buttonStyles}
            >
              Leads
            </Button>
          </Box>
          {/* Show Nav Items only on medium screens and up */}
          <Box
            sx={{ display: { xs: 'none', md: 'flex' }, gap: 1, flexWrap: 'nowrap' }}
          >
            {renderNavItems(true)}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for small screens */}
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 'auto',
            bottom: 'auto',
            maxHeight: 'auto',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            p: 1,
            minWidth: 'auto',
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>{renderNavItems()}</List>
        </Box>
      </Drawer>

      <AddCredits
        user={user}
        setUser={setUser}
        isOpen={addCreditsOpen}
        setIsOpen={setAddCreditsOpen}
      />
      <Toolbar />
    </>
  );
}

export default NavBar;