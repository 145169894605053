import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

Chart.register(...registerables);

function LeadsChart({ data, startDate }) {
  const chartRef = useRef(null); // Reference to the Chart instance
  const canvasRef = useRef(null); // Reference to the canvas element
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State to manage which datasets are visible
  const [activeDatasets, setActiveDatasets] = useState(['leads', 'spending']);

  // Handle toggling datasets
  const handleDatasetToggle = (event, newDatasets) => {
    if (newDatasets.length) { // Ensure at least one dataset remains active
      setActiveDatasets(newDatasets);
    }
  };

  // Define all possible datasets based on active toggles
  const allDatasets = useMemo(() => {
    const datasets = [];

    if (activeDatasets.includes('leads')) {
      datasets.push({
        label: 'Number of Leads',
        data: data.map(item => item.totalLeads),
        backgroundColor: 'rgba(33, 150, 243, 0.8)', // Blue
        borderColor: 'rgba(33, 150, 243, 1)',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: isMobile ? 6 : 8,
        maxBarThickness: 10,
        yAxisID: 'y', // Assign to primary Y-axis
      });
    }

    if (activeDatasets.includes('spending')) {
      datasets.push({
        label: 'Amount Spent ($)',
        data: data.map(item => item.totalSpent),
        backgroundColor: 'rgba(255, 99, 132, 0.8)', // Red
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: isMobile ? 6 : 8,
        maxBarThickness: 10,
        yAxisID: 'y1', // Assign to secondary Y-axis
      });
    }

    return datasets;
  }, [activeDatasets, data, isMobile]);

  // Memoize the chart options
  const options = useMemo(() => {
    return {
      scales: {
        x: {
          type: 'time',
          time: { unit: 'day' },
          min: startDate,
          max: data.length ? new Date(Math.max(...data.map(d => new Date(d.date)))) : undefined,
          title: {
            display: true,
            text: 'Date',
            color: theme.palette.text.primary,
          },
          grid: {
            display: false, // Hide X-axis grid lines for a cleaner look
          },
        },
        y: {
          type: 'linear',
          display: activeDatasets.includes('leads'), // Always display primary Y-axis
          position: 'left',
          title: {
            display: activeDatasets.includes('leads'),
            text: 'Number of Leads',
            color: theme.palette.text.primary,
          },
          grid: {
            color: theme.palette.divider,
            drawOnChartArea: activeDatasets.includes('leads'), // Draw grid lines from primary Y-axis
          },
          ticks: {
            beginAtZero: true,
          },
        },
        y1: {
          type: 'linear',
          display: activeDatasets.includes('spending'), // Display secondary Y-axis based on dataset
          position: 'right',
          title: {
            display: activeDatasets.includes('spending'),
            text: 'Amount Spent ($)',
            color: theme.palette.text.primary,
          },
          grid: {
            color: theme.palette.divider,
            drawOnChartArea: activeDatasets.includes('spending') && !activeDatasets.includes('leads'), // Do not draw grid lines from secondary Y-axis
          },
          ticks: {
            beginAtZero: true,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            title: (tooltipItems) => {
              const itemIndex = tooltipItems[0].dataIndex;
              const chartItem = data[itemIndex];
              return chartItem.formattedDate;
            },
            label: (context) => {
              const label = `${context.dataset.label}: ${context.raw}`;
              return label;
            },
          },
        },
        legend: { display: true, position: 'top' },
        title: {
          display: false,
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      elements: {
        bar: {
          borderWidth: 1,
        },
      },
    };
  }, [data, startDate, theme.palette.text.primary, theme.palette.divider, activeDatasets]);

  // Initialize or update the chart
  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');

    if (!ctx) {
      console.error('Canvas context is not available');
      return;
    }

    if (chartRef.current) {
      // Update datasets and options
      chartRef.current.data.datasets = allDatasets;
      chartRef.current.options = options;
      chartRef.current.update();
    } else {
      // Create the chart for the first time
      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.map(item => item.date),
          datasets: allDatasets,
        },
        options,
      });
    }

    // Cleanup function to destroy the chart instance on unmount
    return () => {
      chartRef.current?.destroy();
      chartRef.current = null;
    };
  }, [allDatasets, options, data]);

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <ToggleButtonGroup
          value={activeDatasets}
          onChange={handleDatasetToggle}
          aria-label="Dataset Toggle"
          sx={{ mr: 1 }}
        >
          <ToggleButton value="leads" aria-label="Leads">
            <PeopleIcon />
          </ToggleButton>
          <ToggleButton value="spending" aria-label="Spending">
            <AttachMoneyIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ height: { xs: 300, sm: 400, md: 500 } }}>
        <canvas ref={canvasRef} />
      </Box>
    </Paper>
  );
}

export default LeadsChart;