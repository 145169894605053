import TextField from '@mui/material/TextField';

const FormInput = ({ label, type = 'text', value, onChange, InputProps }) => (
  <TextField
    label={label}
    type={type}
    value={value}
    onChange={onChange}
    InputProps={InputProps}  // Apply InputProps here
    required
    fullWidth
    margin="normal"
  />
);

export default FormInput;
