// Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormInput from './FormInput';
import FormButton from './FormButton';
import { useAuth } from '../contexts/AuthContext';

function Login() {
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser, setStatus } = useAuth()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen is mobile size

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        formValues
      );
      sessionStorage.setItem('user', JSON.stringify(formValues));
      console.log('login data', response.data);
      setUser(response.data);
      setStatus(response.data.status)
      if (response.data.status === 'pending') {
        // If the user status is pending, redirect to the sign-up fee page
        // navigate('/signup-fee');
        navigate('/leads');

      } else {
        // Otherwise, redirect to the dashboard or another appropriate page
        navigate('/leads');
      }
    } catch (err) {
      console.error('[Client] Login error:', err);
      setError('Invalid email or password');
    }
  };

  // Define your input fields
  const fields = [
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'password', label: 'Password', type: 'password' },
  ];

  // Define your navigation buttons
  const navButtons = [
    { text: 'Sign Up', to: '/signup' },
    { text: 'Forgot Password', to: '/reset-password-request' },
  ];

  return (
    <Box sx={{ maxWidth: 450, mx: 'auto', mt: 4, p: 2, boxShadow: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleSubmit}>
        {fields.map((field, index) => (
          <FormInput
            key={index}
            label={field.label}
            type={field.type}
            value={formValues[field.name]}
            onChange={(e) =>
              setFormValues({ ...formValues, [field.name]: e.target.value })
            }
          />
        ))}
        <FormButton type="submit">Login</FormButton>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {navButtons.map((button, index) => (
            <Grid item xs={6} key={index}>
              <FormButton
                component={Link}
                to={button.to}
                variant="outlined"
                sx={{ fontSize: isMobile ? '0.75rem' : '0.9rem' }}
              >
                {button.text}
              </FormButton>
            </Grid>
          ))}
        </Grid>
      </form>
    </Box>
  );
}

export default Login;