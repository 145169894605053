// src/components/Filters.js
import React from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

function Filters({ 
  filters, 
  handleSearchChange, 
  handleStateChange, 
  handleAmountChange, 
  handleDateChange 
}) {
  return (
    <Box sx={{ mt: 0 }}>
      {/* Search by name */}
      <TextField
        label="Search by name"
        value={filters.search}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />

      {/* State Filter */}
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="state-select-label">State</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={filters.state}
            onChange={(event) => handleStateChange(event.target.value)}
            label="State"
          >
            <MenuItem value="All">All</MenuItem>
            {['NSW', 'QLD', 'VIC', 'ACT', 'SA', 'WA', 'TAS', 'NT'].map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Loan Amount Filter */}
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth sx={{ mb: 0 }}>
          <InputLabel id="loan-amount-select-label">Loan Amount</InputLabel>
          <Select
            labelId="loan-amount-select-label"
            id="loan-amount-select"
            value={filters.loanAmountMinimum}
            onChange={(event) => handleAmountChange(event.target.value)}
            label="Loan Amount"
          >
            <MenuItem value="None">None</MenuItem>
            {['> $100,000', '> $250,000', '> $500,000'].map((amount) => (
              <MenuItem key={amount} value={amount}>
                {amount}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

   {/* Date Filter */}
<Box sx={{ mt: 2 }}>
  <FormControl fullWidth>
    <InputLabel id="date-select-label">Lead Date</InputLabel>
    <Select
      labelId="date-select-label"
      id="date-select"
      value={filters.date}
      onChange={(event) => handleDateChange(event.target.value)}
      label="Lead Date"
    >
      <MenuItem value="All">All</MenuItem>
      <MenuItem value="today">Today</MenuItem>
      <MenuItem value="1-7">1 - 7 days old</MenuItem>
      <MenuItem value=">7">Older than 1 week</MenuItem> {/* Adjusted value */}
    </Select>
  </FormControl>
</Box>

    </Box>
  );
}

export default Filters;
