import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, TextField, Grid, Alert, Modal, IconButton } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { useAuth } from '../contexts/AuthContext';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon for modal

function Webhook() {
  const [url, setUrl] = useState('');
  const [headers, setHeaders] = useState('');
  const [feedback, setFeedback] = useState({ type: '', message: '', response: null });
  const [openModal, setOpenModal] = useState(false); // Modal state
  const { user, getAuthHeader } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWebhookConfig = async () => {
      try {
        const storedConfig = sessionStorage.getItem('webhookConfig');
        let config = storedConfig ? JSON.parse(storedConfig) : null;

        if (!config) {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/webhook/configure`, {
            headers: {
              Authorization: getAuthHeader(),
            },
          });
          config = res.data;
          sessionStorage.setItem('webhookConfig', JSON.stringify(config));
        }

        if (config.url) setUrl(config.url);
        if (config.headers) setHeaders(config.headers);
      } catch (err) {
        console.error('Error fetching webhook configuration:', err.message);
      }
    };

    if (user) {
      fetchWebhookConfig();
    }
  }, [user, getAuthHeader]);

  const handleSendTestRequest = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/webhook/proxy`, { url, headers }, {
        headers: {
          Authorization: getAuthHeader(),
        }
      });
      setFeedback({ type: 'success', message: 'Test request sent successfully!', response: res.data });
    } catch (err) {
      setFeedback({ type: 'error', message: `Error: ${err.message}`, response: null });
    }
  };

  const handleSaveConfiguration = async () => {
    const configuration = { url, headers };
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/webhook/configure`, configuration, {
        headers: {
          Authorization: getAuthHeader(),
        }
      });
      sessionStorage.setItem('webhookConfig', JSON.stringify(configuration));
      setFeedback({ type: 'success', message: 'Webhook configuration saved successfully!', response: null });
      setTimeout(() => navigate('/dashboard'), 1500);
    } catch (error) {
      setFeedback({ type: 'error', message: `Error: ${error.message}`, response: null });
    }
  };

  // Modal open/close handlers
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <AuthWrapper user={user}>
      {user && (
        <Box sx={{ p: 2 }}>
          <Box display="flex" alignItems="center" sx={{mb: 1}}>
            <Typography variant="h4" gutterBottom sx={{mb: 0}}>
              Configure Webhook
            </Typography>
            {/* Info button to trigger the modal */}
            <IconButton onClick={handleOpenModal} sx={{ ml: 1 }}>
              <InfoIcon />
            </IconButton>
          </Box>
          <Typography variant="body1" gutterBottom>
            Purchased leads will be POSTed to the URL you specify. Ensure the destination is set up to receive a JSON body. The Content-Type header is already application/json.
          </Typography>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Headers (JSON)"
                  value={headers}
                  onChange={(e) => setHeaders(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  placeholder='{"Content-Type": "application/json"}'
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleSendTestRequest} sx={{ mr: 2 }}>
                  Send Test Request
                </Button>
                <Button variant="contained" onClick={handleSaveConfiguration}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {feedback.type && (
            <Alert severity={feedback.type} sx={{ mb: 2 }}>
              <Typography variant="h6">{feedback.type === 'success' ? 'Success' : 'Error'}</Typography>
              <pre>{feedback.message}</pre>
              {feedback.response && (
                <pre>{JSON.stringify(feedback.response, null, 2)}</pre>
              )}
            </Alert>
          )}

          {/* Modal for displaying JSON structure */}
          <Modal open={openModal} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width: '80%',
                maxWidth: '600px',
                borderRadius: '8px',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">JSON Structure</Typography>
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <pre>{JSON.stringify({
                name: "Test Name",
                email: "test@example.com",
                phone: "0411111111",
                price: 100,
                date: new Date(),
                details: {
                  monthlyIncome: 5000,
                  loanAmount: 200000,
                  houseValue: 300000,
                  credit: "Good",
                  employment: "Employed",
                  address: "123 Test St",
                  state: "NSW",
                  postcode: "2000",
                  currentRate: "5%",
                  loanType: "Fixed"
                }
              }, null, 2)}</pre>
            </Box>
          </Modal>
        </Box>
      )}
    </AuthWrapper>
  );
}

export default Webhook;