import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { useAuth } from '../contexts/AuthContext';
import LoadingIndicator from './LoadingIndicator';

function InitialDataLoader({ children }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchCalled = useRef(false);
  const { 
    setUser,
    getAuthHeader, 
    setAdminUsers,
    setAvailableLeads, 
    setAvailableLeadsCount,
    setPurchasedLeads, 
    setSettledCount, 
    setConversionRate 
  } = useAuth(); // Get values and setters from the context

  const fetchData = useCallback(async () => {
    const authHeader = getAuthHeader();

    if (fetchCalled.current) {
      console.log("InitialDataLoader: Fetching already completed or in progress");
      // setLoading(false);

      return;
    }
    fetchCalled.current = true;

    try {
      console.log("InitialDataLoader: Starting data fetch");
      const userProfile = await apiService.fetchUserProfile(authHeader);
      setUser(userProfile);

      // Fetch available leads
      const { availableLeads, availableLeadsCount } = await apiService.fetchAvailableLeads(authHeader);
      setAvailableLeads(availableLeads)
      setAvailableLeadsCount(availableLeadsCount)


      // Fetch purchased leads and update context
      const { purchasedLeads, settledCount, conversionRate } = await apiService.fetchPurchasedLeads(authHeader);
      setPurchasedLeads(purchasedLeads); // Store the purchased leads in context
      setSettledCount(settledCount);
      setConversionRate(conversionRate);

      // Fetch admin users if the user is an admin
      if (userProfile.role === 'admin') {
       const adminUsers =  await apiService.fetchAdminUsers(authHeader);
       setAdminUsers(adminUsers)
      }

      console.log("InitialDataLoader: Data fetch completed");
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  }, [setUser, navigate, getAuthHeader, setPurchasedLeads, setSettledCount, setConversionRate, setAdminUsers, setAvailableLeads, setAvailableLeadsCount,]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <LoadingIndicator
        size={40}
        sx={{
          minHeight: '100vh',
        }}
      />
    );
  }

  // Return the children without modification, since data is now in the context
  return <>{children}</>;
}

export default InitialDataLoader;